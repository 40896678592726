@font-face {
  font-family: 'Mark Offc Pro';
  src: url('./assets/fonts/MarkOffcPro.eot');
  src: url('./assets/fonts/MarkOffcPro.eot') format('embedded-opentype'),
    url('./assets/fonts/MarkOffcPro.woff2') format('woff2'),
    url('./assets/fonts/MarkOffcPro.woff') format('woff'),
    url('./assets/fonts/MarkOffcPro.ttf') format('truetype'),
    url('./assets/fonts/MarkOffcPro.svg') format('svg');
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
}
@font-face {
  font-family: 'Mark Offc Pro';
  src: url('./assets/fonts/MarkOffcPro-Medium.eot');
  src: url('./assets/fonts/MarkOffcPro-Medium.eot') format('embedded-opentype'),
    url('./assets/fonts/MarkOffcPro-Medium.woff2') format('woff2'),
    url('./assets/fonts/MarkOffcPro-Medium.woff') format('woff'),
    url('./assets/fonts/MarkOffcPro-Medium.ttf') format('truetype'),
    url('./assets/fonts/MarkOffcPro-Medium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Mark Offc Pro';
  src: url('./assets/fonts/MarkOffcPro-Bold.eot');
  src: url('./assets/fonts/MarkOffcPro-Bold.eot') format('embedded-opentype'),
    url('./assets/fonts/MarkOffcPro-Bold.woff2') format('woff2'),
    url('./assets/fonts/MarkOffcPro-Bold.woff') format('woff'),
    url('./assets/fonts/MarkOffcPro-Bold.ttf') format('truetype'),
    url('./assets/fonts/MarkOffcPro-Bold.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Mark Offc Pro';
  src: url('./assets/fonts/MarkOffcPro-Italic.eot');
  src: url('./assets/fonts/MarkOffcPro-Italic.eot') format('embedded-opentype'),
    url('./assets/fonts/MarkOffcPro-Italic.woff2') format('woff2'),
    url('./assets/fonts/MarkOffcPro-Italic.woff') format('woff'),
    url('./assets/fonts/MarkOffcPro-Italic.ttf') format('truetype'),
    url('./assets/fonts/MarkOffcPro-Italic.svg') format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Mark Offc Pro';
  src: url('./assets/fonts/MarkOffcPro-BoldItalic.eot');
  src: url('./assets/fonts/MarkOffcPro-BoldItalic.eot')
      format('embedded-opentype'),
    url('./assets/fonts/MarkOffcPro-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/MarkOffcPro-BoldItalic.woff') format('woff'),
    url('./assets/fonts/MarkOffcPro-BoldItalic.ttf') format('truetype'),
    url('./assets/fonts/MarkOffcPro-BoldItalic.svg') format('svg');
  font-weight: 500;
  font-style: italic;
}
